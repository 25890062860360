<template>
    <div>
        <div class="top_detail" v-if="getData">
            <el-button type="primary" size="mini" @click="backPage" style="margin-bottom:10px;"><span class="el-icon-back"></span>返回</span></el-button>
            <el-row class="mb_10">
                <el-col :span="4" class="top_big_title">
                    <span class="el-icon-s-order"></span>
                    {{getData.task_name}}
                </el-col>
                <el-col :span="20" style="text-align:right;">
                    <el-button @click="getDetil()">立即同步</el-button>
                    <el-button type="primary" v-if="getData && getData.type != 2 && getData.status == 2" @click="start_use">启用</el-button>
                    <el-button type="danger" v-if="getData && getData.type != 2 && getData.status == 1" @click="stop_use">停用</el-button>
                </el-col>
            </el-row>
            <el-row class="text_left fs_14">
                <el-col :span="18">
                    <el-row  class="mb_10">
                        <el-col :span="8">
                            创建人：{{getData.user_name}}
                        </el-col>
                        <el-col :span="8">
                            已导入商品：{{getData.item_num}}
                        </el-col>
                        <el-col :span="8">
                            导入物料：{{getData.task_mark}}
                        </el-col>
                    </el-row>
                    <el-row class="mb_10">
                        <el-col :span="8">
                            创建时间：{{getTime(getData.create_time)}}
                        </el-col>
                        <el-col :span="8">
                            更新频率：{{getData.update_frequency}}
                        </el-col>
                    </el-row>
                    <el-row class="">
                        <el-col :span="8">
                            上次更新：{{getTime(getData.update_time)}}
                        </el-col>
                        <el-col :span="8">
                            关联专题：{{getData.topic_name}}
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="6" class="form_status_fa">
                    <div class="form_status" v-if="getData && getData.type != 2">
                        状态 
                        <div class="status_show">{{getStatusToDetail(getData.status)}}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="myContent" v-if="getData">
            <div class="bg_fff">
                <el-table
                    v-loading="loading"
                    :data="getData.list"
                    border
                    style="width: 100%">
                    <el-table-column
                    fixed
                    prop="id"
                    label="ID"
                    width="70">
                    </el-table-column>
                    <el-table-column
                    fixed
                    prop="update_time"
                    label="更新时间">
                    </el-table-column>
                    <el-table-column
                    fixed
                    prop="update_item_num"
                    label="更新商品数量">
                    </el-table-column>
                    <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                        <el-button @click="openDetail(scope.row)" type="text" size="small">详情</el-button>
                    </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    style="margin-top:15px;"
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[20, 30, 40, 50]"
                    :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog
            style="text-align:left;"
            title="导入详情"
            :visible.sync="dialogVisible"
            width="40%">
            <div class="dialogContent" v-loading="remark_loading">
                <div v-for="(item,index) in remark_list" :key="index">
                    {{item}}
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
               <el-button type="primary" @click="dialogVisible=false">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import * as api from '@/config/api'
export default {
    data() {
        return {
            id:'',
            page:1,
            pagesize:20,
            loading:false,
            getData:null,
            total:undefined,
            dialogVisible:false,
            remark_list:[],
            remark_loading:false,
        }
    },
    created() {
    },
    activated() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id;
            this.getDetil({id:this.id});
        }
    },
    methods:{
        handleSizeChange(e){
            this.pagesize = e;
            this.getDetil({id:this.id});
        },
        handleCurrentChange(e){
            this.page = e;
            this.getDetil({id:this.id});
        },
        getDetil(param={}){
            if(param.id === undefined){
                param.id = this.id
            }
            this.loading = true;
            param.page = this.page;
            param.pagesize = this.pagesize;
            api.getComImportDetail(
                param,
                res=>{
                    this.loading = false;
                    console.log(res);
                    this.getData = res.data || null;
                    this.total = Number(res.data.count) || undefined; 
                },
                err=>{
                    this.loading = false;
                    console.log(err);
                }
            )
        },
        // 根据时间戳计算当前时间
        getTime(time){
            time = Number(time) * 1000
            let ntime = new Date(time);
            let year = ntime.getFullYear();
            let yue = ntime.getMonth()+1;
            yue = yue <10 ? '0'+yue : yue;

            let ri = ntime.getDate();
            ri = ri <10 ? '0'+ri : ri;

            let hour = ntime.getHours();
            hour = hour <10 ? '0'+hour : hour;

            let fen = ntime.getMinutes();
            fen = fen <10 ? '0'+fen : fen;

            let miao = ntime.getSeconds();
            miao = miao <10 ? '0'+miao : miao;
            
            return `${year}-${yue}-${ri} ${hour}:${fen}:${miao}`
        },
         // 根据状态码显示对应的内容
        getStatusToDetail(status){
            if (status === undefined)return '';
            if(status == 1){
                return '启用'
            }else if(status == 2){
                return '停用'
            }else{
                return '其他'
            }
        },
        // 停止使用
        stop_use(){
            let id = this.id;
            api.updateTaskStatus({
                id,
                status:2,
            },res=>{
                this.$message({
                    message: '修改成功！',
                    type: 'success'
                });
                this.getDetil();
            },err=>{
                this.$message({
                    message: '修改失败！',
                    type: 'success'
                });
                console.error(err);
            })
        },
         // 启用
        start_use(){
             let id = this.id;
            api.updateTaskStatus({
                id,
                status:1,
            },res=>{
                this.$message({
                    message: '修改成功！',
                    type: 'success'
                });
                this.getDetil();
            },err=>{
                this.$message({
                    message: '修改失败！',
                    type: 'success'
                });
            })
        },
        // 打开详情
        openDetail(e){
            this.remark_list = [];
            this.remark_loading =true;
            this.dialogVisible = true;
            api.getComItemTaskDetail(
                {
                    task_id:e.task_id,
                    log_id:e.id,
                },
                res=>{
                    this.remark_loading =false;
                    this.remark_list = res.data.remark_list || [];
                },err=>{
                    this.remark_loading =false;
                    this.remark_list = [];
                    console.error(err);
                }
            )
        },
        //返回上一层
        backPage(){
            this.$router.go(-1);
        },
    }
    
}
</script>
<style lang="scss">
    .top_detail{
        background-color: #fff;
        padding: 30px;
        text-align: left;
    }
    .top_big_title{
        font-size: 22px;
        font-weight: 700;
        text-align: left;
    }
    .text_left{
        text-align: left;
    }
    .fs_14{
        font-size: 14px;
    }
    .mb_10{
        margin-bottom: 10px;
    }
    .form_status_fa{
        position: relative;
        height: 100%;
    }
    .form_status{
        color: #999;
        float:right;
        text-align: center;
        .status_show{
            color:#333;
            font-size: 22px;
        }
    }
    .myContent{
        padding: 20px;
        .bg_fff{
            background-color: #fff;
            padding: 20px;
        }
    }
    .dialogContent{
        max-height: 300px;
        overflow: auto;
    }
</style>